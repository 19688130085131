import "./style.css";
import DotQuy from "../Images/dotquy.jpg";
const D4_DotQuy = ({}) => {
  return (
    <div id="section4-4" className="section">
      <div className="container">
        <h2>4. ĐỘT QUỴ</h2>
        <img
          src={DotQuy}
          width={"100%"}
          style={{ borderRadius: 10, marginTop: 10, marginBottom: 10 }}
          alt=""
        ></img>
      </div>
    </div>
  );
};
export default D4_DotQuy;
